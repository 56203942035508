import Vue from 'vue'
import store from '@/store'
import moment from 'moment'
import options from '../common/option'

const dateFormat = (date, format) => {
  if (!date) {
    return ''
  }
  if (!format) {
    format = 'YYYY-MM-DD HH:mm'
  }
  return moment(date).format(format)
}

const optionsFormat = (key, options) => {
  if (key === '' || !options) {
    return key
  }
  for (let item of options) {
    if (item.value * 1 === key * 1) {
      return item.label
    }
  }
  return key
}
const objectFormat = (key, object) => {
  if (key === '' || !object) {
    return key
  }
  let val = object[key]
  if (val) {
    return val
  }
  return key
}

const f = {
  gameFormat (key) {
    return optionsFormat(parseInt(key), store.getters.games)
  },
  channelFormat (key) {
    return optionsFormat(parseInt(key), store.getters.channels)
  },
  serverFormat (key) {
    if (!key) {
      return key
    }
    return optionsFormat(parseInt(key), store.getters.servers)
  },
  productFormat (key) {
    return optionsFormat(key, store.getters.products)
  },
  payRangeFormat (key) {
    return optionsFormat(key, options.payRangeOptions)
  },
  loginCountFormat (key) {
    return optionsFormat(key, options.loginCountOptions)
  },
  payCountFormat (key) {
    return optionsFormat(key, options.payCountOptions)
  },
  intervalFormat (key) {
    return optionsFormat(key, options.intervalOptions)
  },
  mapRegionFormat (key) {
    return objectFormat(key, options.nameMap)
  },
  contrastFormat (val) {
    let obj = options.contrastOptions.get(val)
    return obj
      ? obj.name ? obj.name : val
      : val
  },
  autoFormat (key, value) {
    if (key === 'channel' || key === 'channelId') {
      if (value === '统计') {
        return '统计'
      }
      return this.channelFormat(value)
    }
    if (key === 'server' || key === 'serverId' || key === 'fromServerId') {
      return this.serverFormat(value)
    }
    if (key === 'product') {
      return this.productFormat(value)
    }
    if (key === 'payRange') {
      return this.payRangeFormat(value)
    }
    if (key === 'loginCount') {
      return this.loginCountFormat(value)
    }
    if (key === 'payCount') {
      return this.payCountFormat(value)
    }
    if (key === 'interval') {
      return this.intervalFormat(value)
    }
    if (key === 'region') {
      return this.mapRegionFormat(value)
    }
    if (key === 'createTime' || key === 'lastLoginTime') {
      return this.dateFormat(value)
    }
    if (key === 'cycle' || key === 'target') {
      return this.contrastFormat(value)
    }
    return value
  },
  optionsFormat,
  dateFormat,
  k2vOrder (key, val) {
    if (key === 'channel_id') {
      return this.channelFormat(val)
    }
    if (key === 'status') {
      return optionsFormat(parseInt(val), options.orderStatusOptions)
    }
    if (key === 'money') {
      return (val / 100).toFixed(2) + ' 元'
    }
    if (key === 'price') {
      return val + ' 分'
    }
    return val
  },
  money2CNY (money, currency) {
    let rate = options.currency2CNYRate[currency]
    if (!rate) {
      return money
    }
    return money * rate
  }
}

Vue.filter('game', function (key) {
  return f.gameFormat(key)
})

Vue.filter('channel', function (key) {
  return f.channelFormat(key)
})

Vue.filter('k2vOptions', function (key, options) {
  return f.optionsFormat(key, options)
})

Vue.filter('money2CNY', function (money, currency) {
  return f.money2CNY(money, currency)
})

export default f
