import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout'
import p from '@/common/privilege'

Vue.use(Router)

// 在当前页面时候再次点击当前页面，会有警告！
// 原因：在路由中添加了相同的路由。
// 解决：
// 重写路由的push方法
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

function load (view) {
  // return () => import(`@/views/${view}.vue`) 这个写法babel-eslint那个B玩意报错，还得用下面的写法，傻逼babel-eslint
  return resolve => require([`@/views/${view}.vue`], resolve)
}

export default new Router({
  routes: [
    { path: '/login', component: load('Login'), hidden: true },
    {
      path: '/',
      hidden: true,
      component: Layout,
      redirect: '/home',
      children: [{ path: '/home', name: '首页', component: load('Home'), icon: 'home' }]
    },
    // TODO: 暂时隐藏
    // {
    //   path: '/dashboard',
    //   component: Layout,
    //   privileges: [p.P_C],
    //   children: [{ path: '/dashboard', name: '游戏概况', component: load('Dashboard'), icon: 'globe' }]
    // },
    {
      path: '/report',
      component: Layout,
      name: '数据报告',
      subMenu: true,
      privileges: [p.P_C, p.P_S, p.P_CPS],
      meta: { breadcrumbLinkDisable: true },
      icon: 'file-invoice',
      children: [
        { path: '/cps-daily', name: 'cps日报', component: load('Report/CpsDaily/index'), icon: 'laptop' },
        { path: '/cps-monthly-report', name: 'cps月报', component: load('Report/CpsMonthlyReport/index'), icon: 'flag' }
        // TODO: 暂时隐藏
        // { path: '/daily', name: '数据日报', component: load('Report/Daily'), icon: 'poll' },
        // { path: '/Monthly', name: '数据月报', component: load('Report/Monthly'), icon: 'poll-h' },
        // { path: '/LtvDaily', name: 'Ltv日报', component: load('Report/DataDaily/Ltv'), icon: 'project-diagram' },
        // { path: '/RetainedDaily', name: '留存日报', component: load('Report/DataDaily/Retained'), icon: 'project-diagram' },
        // { path: '/ServerDaily', name: '分服日报', component: load('Report/ServerDaily'), icon: 'project-diagram', privileges: [p.P_S] },
        // { path: '/ServerMonthly', name: '分服月报', component: load('Report/ServerMonthly'), icon: 'project-diagram', privileges: [p.P_S] }
      ]
    },
    // {
    //   path: '/custom',
    //   hidden: true,
    //   component: Layout,
    //   children: [{ path: '/custom', name: '自制概况', component: load('CustomTarget'), icon: 'user-cog' }]
    // },
    /** TODO: 暂时隐藏
    {
      path: '/player',
      component: Layout,
      name: '游戏玩家',
      subMenu: true,
      privileges: [p.P_C],
      meta: { breadcrumbLinkDisable: true },
      icon: 'user-astronaut',
      children: [
        { path: '/player/new', name: '新增玩家', component: load('Player/PlayerNew'), icon: 'chart-line' },
        { path: '/player/active', name: '活跃玩家', component: load('Player/PlayerActive'), icon: 'running' },
        { path: '/player/keep', name: '玩家留存', component: load('Player/PlayerKeep'), icon: 'chart-bar' },
        { path: '/player/device', name: '设备相关', component: load('Player/PlayerDevice'), icon: 'mobile-alt' },
        { path: '/player/pay/conversion', name: '付费转化', component: load('Player/PayConversion'), icon: 'random' },
        { path: '/player/level', name: '等级分布', component: load('Player/PlayerLevel'), icon: 'sort-numeric-up-alt' }
      ]
    },
    {
      path: '/pay',
      component: Layout,
      name: '收入分析',
      privileges: [p.P_C],
      subMenu: true,
      meta: { breadcrumbLinkDisable: true },
      icon: 'yen-sign',
      children: [
        { path: '/pay/money', name: '收入数据', component: load('Pay/PayMoney'), icon: 'chart-line' },
        { path: '/pay/permeate', name: '付费渗透', component: load('Pay/PayPermeate'), icon: 'chart-area' },
        { path: '/pay/ltv', name: '用户价值', component: load('Pay/Ltv'), icon: 'donate' },
        { path: '/pay/dist', name: '充值分布', component: load('Pay/Distribution'), icon: 'chart-pie' },
        { path: '/pay/keep', name: '付费留存', component: load('Pay/PayKeep'), icon: 'chart-bar' }
      ]
    },
    {
      path: '/user',
      component: Layout,
      name: '用户来源',
      privileges: [p.P_C],
      subMenu: true,
      meta: { breadcrumbLinkDisable: true },
      icon: 'street-view',
      children: [
        { path: '/roll', name: '滚服分布', component: load('Roll/Distribution'), icon: 'chart-pie' },
        { path: '/map/region', name: '地理位置', component: load('Map/Region'), icon: 'map-marked-alt' },
        { path: '/process', name: '流程统计', component: load('Process/process'), icon: 'hourglass-half' }
      ]
    },
    */
    // {
    //   path: '/process',
    //   component: Layout,
    //   meta: { breadcrumbLinkDisable: true },
    //   // icon: 'street-view',
    //   children: [{ path: '/process', name: '流程统计', component: load('Process/process'), icon: 'hourglass-half' }]
    // },
    /** TODO: 暂时隐藏
    {
      path: '/fee',
      component: Layout,
      name: '计费账单',
      subMenu: true,
      privileges: [p.P_O, p.P_B],
      meta: { breadcrumbLinkDisable: true },
      icon: 'file-invoice-dollar',
      children: [
        { path: '/fee/bills', name: '渠道账单', component: load('Fee/Bills'), icon: 'hand-holding-usd', privileges: [p.P_B] },
        { path: '/fee/order', name: '订单查询', component: load('Fee/Order/index'), icon: 'search-dollar', privileges: [p.P_O] },
        { path: '/fee/bill', name: '账单明细', component: load('Fee/Bill'), icon: 'file-invoice-dollar', privileges: [p.P_G] }
      ]
    },
    {
      path: '/search',
      component: Layout,
      name: '玩家查询',
      subMenu: true,
      privileges: [p.P_O, p.P_B, p.P_CPS],
      meta: { breadcrumbLinkDisable: true },
      icon: 'search-location',
      children: [
        { path: '/search/whale', name: '鲸鱼用户', component: load('Search/Whale'), icon: 'fish', privileges: [p.P_B, p.P_CPS] },
        { path: '/search/user', name: '用户查询', component: load('Search/User'), icon: 'user-lock', privileges: [p.P_O, p.P_CPS] },
        { path: '/search/role', name: '角色查询', component: load('Search/Role'), icon: 'gamepad', privileges: [p.P_O, p.P_CPS] },
        { path: '/search/transfer', name: '账户迁移', component: load('Search/Transfer'), icon: 'user-lock', privileges: [p.P_G] }
      ]
    },
    {
      path: '/s_portrait',
      component: Layout,
      name: '分服画像',
      subMenu: true,
      privileges: [p.P_G],
      meta: { breadcrumbLinkDisable: true },
      icon: 'users',
      children: [
        { path: '/s_portrait/contrast', name: '数据对比', component: load('ServerPortrait/Contrast'), icon: 'retweet', privileges: [p.P_B] },
        { path: '/s_portrait/stage', name: '阶段数据', component: load('ServerPortrait/Stage'), icon: 'signal', privileges: [p.P_O] }
      ]
    },
    {
      path: '/policy',
      component: Layout,
      name: '隐私协议',
      subMenu: true,
      privileges: [p.P_G],
      meta: { breadcrumbLinkDisable: true },
      icon: 'shield-alt',
      children: [
        { path: '/policy/organize', name: '组织主体', component: load('Policy/Organize'), icon: 'leaf' }
      ]
    },
    */
    // {
    //   path: '/tg',
    //   component: Layout,
    //   name: '广告数据',
    //   subMenu: true,
    //   privileges: [p.P_C],
    //   meta: { breadcrumbLinkDisable: true },
    //   icon: 'file-invoice',
    //   children: [
    //     { path: '/tg_report', name: '数据报表', component: load('Tg/Daily'), icon: 'poll' }
    //   ]
    // },
    // 暂时不用
    // {
    //   path: '/ip',
    //   component: Layout,
    //   name: 'IP限制',
    //   subMenu: true,
    //   privileges: [p.P_G],
    //   meta: { breadcrumbLinkDisable: true },
    //   icon: 'american-sign-language-interpreting',
    //   children: [
    //     { path: '/ip/close', name: 'TOP IP', component: load('IP/TOPIP'), icon: 'unlock-alt' },
    //     { path: '/ip/open', name: '黑名单', component: load('IP/Blacklist'), icon: 'lock' }
    //   ]
    // },
    {
      path: '/403',
      component: Layout,
      name: '403',
      hidden: true,
      children: [{ path: '/403', name: 'Forbidden', component: load('Error/403') }]
    },
    // Always leave this last one
    { path: '*', component: load('Error/404'), hidden: true }
  ]
})
